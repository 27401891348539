import {createAsyncThunk}         from '@reduxjs/toolkit';
import {
  getObject,
  getCategory,
  getProduct
}                                 from "services/market";
import {
  getFormattedCategory,
  getFormattedObject,
  getFormattedProduct
}                                 from "pages/market/helpers";
import {setScreen} from "./slice";
import {MARKET_SCREEN} from "../../pages/market/consts";

export const getObjectInfo = createAsyncThunk(
  'market/object',
  async (slug: string, {rejectWithValue}) => {

    try {
      const res = await getObject(slug);
      const t = getFormattedObject(res.data);

      return new Promise((resolve) => {
        setTimeout(() => {
          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          // @ts-ignore
          resolve(t);
        }, 3_000)
      });

    } catch (e) {
      const err = e as Error;
      return rejectWithValue(err.response.data.code);
    }
  }
);

export const getCategoryInfo = createAsyncThunk(
  'market/category',
  async (id: number | string, {rejectWithValue}) => {
    try {
      const res = await getCategory(id);
      return getFormattedCategory(res.data);
    } catch (e) {
      const err = e as Error;
      return rejectWithValue(err.response.data.code);
    }
  }
);

export const getProductInfo = createAsyncThunk(
  'market/product',
  async (id: number | string, {rejectWithValue}) => {
    try {
      const res = await getProduct(id);
      return getFormattedProduct(res.data);
    } catch (e) {
      const err = e as Error;
      return rejectWithValue(err.response.data.code);
    }
  }
);

export const initMarket = createAsyncThunk(
  'market/init',
  async ({slug, params}: {slug?: string; params: URLSearchParams} , {dispatch}) => {

    const category = params.get('category');
    const product = params.get('product');

    dispatch(getObjectInfo(slug as string));
    if (category) {
      dispatch(getCategoryInfo(category));
    }
    if (product) {
      dispatch(getProductInfo(product));
    }

    if (category && !product) {
      dispatch(setScreen(MARKET_SCREEN.CATEGORY_LIST));
    } else if (category && product) {
      dispatch(setScreen(MARKET_SCREEN.PRODUCT));
    }
  }
);

